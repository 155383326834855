import { Modal } from 'bootstrap';
// Firebase

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// IMPORTANT: Remember to comment one of the suitable one

// Production
import { firebaseConfig } from './data/prod';

// Development
// import { firebaseConfig } from './data/dev';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

import { getAuth, onAuthStateChanged } from "firebase/auth";


document.addEventListener("DOMContentLoaded", () => {
    const auth = getAuth();
    var unsub = onAuthStateChanged(auth, (user) => {
        unsub();
        if (document.querySelector("#navbarList")) {
            let navbar = document.querySelector("#navbarList");

            if (user) {
                navbar.innerHTML += `<li class="nav-item"><a href="./dashboard.html">
                    <button type="button" class="btn btn-info fw-bold px-4 py-2 rounded-pill text-white">Dashboard</button></a></li>`;
            } else {
                navbar.innerHTML += `<li class="nav-item"><a href="./signin.html">
                    <button type="button" class="btn btn-info fw-bold px-4 py-2 rounded-pill text-white">Login</button></a></li>`;
            }
        }
    });

});
